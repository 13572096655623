@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar width and color for larger screens */
@media (min-width: 768px) {
  .scrollbar-wider::-webkit-scrollbar {
    width: 12px; /* Adjust scrollbar width for larger screens */
  }

  .scrollbar-wider::-webkit-scrollbar-thumb {
    background-color: #ab79f3; /* Adjust thumb color for larger screens */
  }
}

/* Custom scrollbar width and color for all screens */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px; /* Adjust scrollbar width for all screens */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #dcdcdc; /* Adjust thumb color for all screens */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

li {
  list-style-type: none;
}
.video-container {
height: 190px; 
width: 80%;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

